<template>
  <v-container fluid class="register d-flex flex-column pa-0">
    <!-- INTESTAZIONE desktop -->
    <v-row no-gutters align="center" class="page-header d-none d-sm-flex pl-8">
      <h2>{{ $t("register.title") }}</h2>
    </v-row>

    <!-- INTESTAZIONE mobile -->
    <v-row
      no-gutters
      justify="start"
      align="center"
      class="page-header d-flex d-sm-none pl-7"
    >
      <h2>{{ $t("register.title") }}</h2>
    </v-row>

    <!-- STEPPER -->
    <v-container fluid class="pa-0">
      <v-stepper v-model="currentStep" :change="toTop()">
        <h2
          class="py-3 text-uppercase text-center white"
          v-if="currentStep === 1"
        >
          {{ $t("register.cardCheck.switch-text") }}
        </h2>
        <div style="height:50px" class="white" v-if="!endSteps">
          <v-stepper-header v-show="visibleStepper">
            <template v-for="n in steps">
              <v-stepper-step
                :key="steps.indexOf(n)"
                :complete="currentStep > n"
                :step="steps.indexOf(n) + 1"
              >
              </v-stepper-step>

              <v-divider
                v-if="steps.indexOf(n) + 1 < steps.length"
                :key="`${steps.indexOf(n)}-divider`"
              ></v-divider>
            </template>
          </v-stepper-header>
        </div>

        <v-stepper-items>
          <v-stepper-content class="pa-0" step="1">
            <!-- componente con due possibili form: 
              - verifica c.f./p.iva e numero tessera
              - compilazione dati per richiesta tessera
            -->
            <CardCheck
              @next="nextStep"
              @end="endPendingRegistration"
              @showStepper="showStepper"
              @companyChanged="loadWarehouses"
              :warehouses="warehouses"
              :companies="companies"
            />
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="2">
            <!-- inserimento user e password per clienti muniti di codice -->
            <CreateOnlineAccount
              @end="endRegistration"
              @prev="backToFirstStep"
              :registerData="navigationParams.user"
            />
          </v-stepper-content>

          <v-stepper-content class="pa-0" step="3">
            <!-- registrazione completata -->
            <RegistrationCompleted :pending="pending" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-container>

    <RecaptchaDisclaimer></RecaptchaDisclaimer>
  </v-container>
</template>

<script>
import addressService from "~/service/addressService";
import CardCheck from "@/components/registration/CardCheck";
import CreateOnlineAccount from "@/components/registration/CreateOnlineAccount";
import RegistrationCompleted from "@/components/registration/RegistrationCompleted";
import RecaptchaDisclaimer from "@/commons/components/RecaptchaDisclaimer.vue";
import { requiredValue, isEmail, isDate } from "@/validator/validationRules";
export default {
  components: {
    CardCheck,
    CreateOnlineAccount,
    RegistrationCompleted,
    RecaptchaDisclaimer
  },
  data() {
    return {
      steps: [{ name: 1 }, { name: 2 }, { name: 3 }],
      currentStep: 1,
      requiredRules: requiredValue,
      dateRules: [requiredValue("Digitare la data di nascita"), isDate()],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      errors: [],
      response: {},
      loading: false,
      navigationParams: {},
      endSteps: false,
      visibleStepper: true,
      pending: false,
      warehouses: [],
      companies: [
        {
          companyId: 43,
          companyName: "Sicilia"
        },
        {
          companyId: 44,
          companyName: "Sardegna"
        }
      ]
    };
  },
  computed: {},
  methods: {
    /**
     * caricamento della lista dei punti vendita
     */
    async loadWarehouses(companyId) {
      let data = await addressService.findWarehouse({
        parent_company_id: companyId
      });
      if (data.warehouses.length) {
        this.warehouses = data.warehouses;

        // this.warehouses.unshift({
        //   warehouseId: null,
        //   name: "NESSUN PUNTO VENDITA",
        //   codInt: "",
        //   address: {
        //     addressId: null,
        //     city: "ROMA"
        //   }
        // });
      }
    },
    showStepper(value) {
      this.$nextTick(() => {
        this.visibleStepper = value;
      });
    },
    toTop() {
      //TODO: verificare l'animazione su dispositivi
      // this.$nextTick(() => {
      //   this.$vuetify.goTo(0);
      // });
    },
    backToFirstStep() {
      this.currentStep = 1;
    },
    nextStep(params) {
      this.navigationParams = {};
      if (params) {
        this.navigationParams = params;
      }
      this.currentStep = params.step;
    },
    async endRegistration() {
      this.endSteps = true;
      this.currentStep = 3;
      this.pending = false;
    },
    endPendingRegistration() {
      this.endSteps = true;
      this.currentStep = 3;
      this.pending = true;
    }
  },
  mounted() {
    // this.loadWarehouses();
  },
  watch: {
    steps(val) {
      if (this.currentStep > val) {
        this.currentStep = val;
      }
    }
  }
};
</script>
