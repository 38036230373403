<template>
  <v-container
    class="registration-completed text-center text-md-left align-center align-md-start"
  >
    <!-- se il flusso di registrazione è per utente con carta (solo aggiornamento dati) -->
    <div class="tab-card">
      <h2 class="text-uppercase mb-7 text-center" v-if="!pending">
        {{ $t("register.registrationCompleted.title") }}
      </h2>
      <!-- se il flusso di registrazione è per un utente nuovo senza carta -->
      <div v-else>
        <h2
          class="text-uppercase mb-7 text-center"
          v-html="$t('register.registrationCompleted.noCard.titlePending')"
        ></h2>
        <div class="mb-7 text-center"
          v-html="$t('register.registrationCompleted.noCard.subtitlePending')"
        ></div>
      </div>
      <v-row no-gutters justify="center">
        <v-btn class="secondary" x-large :to="{ name: 'Home' }">{{
          $t("register.registrationCompleted.goToHome")
        }}</v-btn>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  props: ["pending"]
};
</script>
